import { stringContains } from '~/utils/sanitizer';

export function calcPercent(part, total) {
  return Math.round((part / total) * 100);
}

export function isNutrientPerfect(nutrient) {
  if (!nutrient.recommendation) {
    return false;
  }
  return (
    nutrient.total > nutrient.recommendation?.values.min &&
    (nutrient.recommendation?.values.max
      ? nutrient.total < nutrient.recommendation?.values.max
      : true)
  );
}

export function isNutrientInDeficiency(nutrient) {
  if (!nutrient.recommendation) {
    return false;
  }
  return (
    nutrient.total < nutrient.recommendation?.values.min &&
    nutrient.total < nutrient.recommendation?.values.min / 2
  );
}

export function isNutrientInInsufficiency(nutrient) {
  if (!nutrient.recommendation) {
    return false;
  }
  return (
    nutrient.total < nutrient.recommendation.values.min &&
    nutrient.total > nutrient.recommendation.values.min / 2 &&
    (nutrient.recommendation.values.max
      ? nutrient.total < nutrient.recommendation.values.max
      : true)
  );
}

export function isNutrientInExcess(nutrient) {
  if (!nutrient.recommendation) {
    return false;
  }
  return (
    nutrient.recommendation?.values.max &&
    nutrient.total > nutrient.recommendation?.values.max
  );
}

export function setRecommendationForAminoAcid(
  nutrientLabel: string,
  patientWeightInKg: number,
) {
  const recoTable = [
    {
      nutrientLabel: 'isoleucine',
      recommendationByKg: { min: 19 },
    },
    {
      nutrientLabel: 'leucine',
      recommendationByKg: { min: 42, max: 530 },
    },
    {
      nutrientLabel: 'lysine',
      recommendationByKg: { min: 38 },
    },
    {
      nutrientLabel: 'methionine',
      recommendationByKg: { min: 19 },
    },
    {
      nutrientLabel: 'phenylalanine',
      recommendationByKg: { min: 33 },
    },
    {
      nutrientLabel: 'threonine',
      recommendationByKg: { min: 20 },
    },
    {
      nutrientLabel: 'tryptophan',
      recommendationByKg: { min: 5 },
    },
    {
      nutrientLabel: 'valine',
      recommendationByKg: { min: 24 },
    },
    {
      nutrientLabel: 'histidine',
      recommendationByKg: { min: 14 },
    },
  ];

  const recoForNutrient = recoTable.find((item) =>
    nutrientLabel.includes(item.nutrientLabel),
  )?.recommendationByKg;

  return recoForNutrient
    ? {
        min: (recoForNutrient.min * patientWeightInKg) / 1000,
        max: recoForNutrient.max
          ? (recoForNutrient.max * patientWeightInKg) / 1000
          : undefined,
      }
    : undefined;
}

function aggreagteSameContributors(accOriginal, contributors) {
  return contributors.reduce((acc, contributor) => {
    const existingContributorIndex = acc.findIndex(
      (c) => c.foodId === contributor.foodId,
    );
    if (existingContributorIndex > -1) {
      acc[existingContributorIndex].calories += contributor.calories;
      acc[existingContributorIndex].quantity += contributor.quantity;
    } else {
      acc.push(contributor);
    }
    return acc;
  }, accOriginal);
}

export function parseNutrientsAnalysis(
  nutrients,
  nutrientsData,
  patientWeight,
  pace,
) {
  const result = nutrientsData.reduce(
    (acc, nutrient) => {
      let isNutrientAnalysed = false;

      const nutrientInfos = nutrients.find((n) => n.id === nutrient.nutrientId);

      //add translation
      nutrient.translations = nutrientInfos.nutrients_translations;

      //Paced quantity
      nutrient.total =
        pace?.pace === 'day'
          ? nutrient.totalInGram / pace.amount
          : (nutrient.totalInGram / pace.amount) * 7;

      //sort contributors
      nutrient.contributors = nutrient.contributors.sort(
        (a, b) => b.quantity - a.quantity,
      );

      switch (nutrientInfos?.type) {
        case 'macro':
          acc.macros.push(nutrient);
          isNutrientAnalysed = true;
          break;

        case 'vitamin':
          acc.vitamins.push(nutrient);
          isNutrientAnalysed = true;
          break;
        case 'mineral':
          acc.minerals.push(nutrient);
          isNutrientAnalysed = true;
          break;
        case 'amino-acid':
          const reco = setRecommendationForAminoAcid(
            nutrient.label.toLowerCase(),
            patientWeight,
          );
          if (reco) {
            nutrient.recommendation = {
              byKg: true,
              values: reco,
            };
          }

          acc.aminoAcids.push(nutrient);
          isNutrientAnalysed = true;
          break;

        case 'fatty-acid':
          if (
            ['18:3', '2:5', '22:6'].find((v) =>
              stringContains(nutrient.label.toLowerCase(), v),
            )
          ) {
            // Total Omega 3
            const findSynthetic = acc.greaseAcids.find(
              ({ id }) => id === 'omega3-total',
            );
            findSynthetic.total += nutrient.totalInGram;
            findSynthetic.highestContributor = nutrient.highestContributor;
            findSynthetic.contributors.push(...nutrient.contributors);
          }

          if (stringContains(nutrient.label.toLowerCase(), '18:3')) {
            // Omega 3 ALA
            const findSynthetic = acc.greaseAcids.find(
              ({ id }) => id === 'omega3-ALA',
            );
            findSynthetic.total += nutrient.totalInGram;
            findSynthetic.highestContributor = nutrient.highestContributor;
            findSynthetic.contributors.push(...nutrient.contributors);
            findSynthetic.recommendation = nutrient.recommendation;
          } else if (
            ['2:5', '22:6'].find((v) =>
              stringContains(nutrient.label.toLowerCase(), v),
            )
          ) {
            // EPA&DHA
            const findSynthetic = acc.greaseAcids.find(
              ({ id }) => id === 'omega3-EPA-DHA',
            );
            findSynthetic.total += nutrient.totalInGram;
            findSynthetic.highestContributor = nutrient.highestContributor;
            findSynthetic.contributors = aggreagteSameContributors(
              findSynthetic.contributors,
              nutrient.contributors,
            );
          } else if (
            ['18:2', '18:4', '20:4'].find((v) =>
              stringContains(nutrient.label.toLowerCase(), v),
            )
          ) {
            // Total Omega 6
            const findSynthetic = acc.greaseAcids.find(
              ({ id }) => id === 'omega6-total',
            );
            findSynthetic.total += nutrient.totalInGram;
            findSynthetic.highestContributor = nutrient.highestContributor;
            findSynthetic.contributors = aggreagteSameContributors(
              findSynthetic.contributors,
              nutrient.contributors,
            );
          } else if (!stringContains(nutrient.label.toLowerCase(), '22:5')) {
            acc.greaseAcids.push(nutrient);
          }

          isNutrientAnalysed = true;
          break;
        default:
          if (
            ['alcohol', 'cholesterol', 'sugar'].find((v) =>
              stringContains(nutrient.label.toLowerCase(), v),
            )
          ) {
            acc.macros.push(nutrient);
            isNutrientAnalysed = true;
          } else if (
            ['biotin', 'folate', 'choline'].find((v) =>
              stringContains(nutrient.label.toLowerCase(), v),
            )
          ) {
            acc.vitamins.push(nutrient);
            isNutrientAnalysed = true;
          } else if (
            ['iodine'].find((v) =>
              stringContains(nutrient.label.toLowerCase(), v),
            )
          ) {
            acc.minerals.push(nutrient);
            isNutrientAnalysed = true;
          } else {
            acc.micros.push(nutrient);
            isNutrientAnalysed = true;
          }
      }

      if (isNutrientAnalysed) {
        if (isNutrientPerfect(nutrient)) {
          acc.statusCount.perfect++;
        } else if (isNutrientInDeficiency(nutrient)) {
          acc.statusCount.deficiency++;
        } else if (isNutrientInInsufficiency(nutrient)) {
          acc.statusCount.insufficiency++;
        } else if (isNutrientInExcess(nutrient)) {
          acc.statusCount.excess++;
        }
      }

      return acc;
    },
    {
      macros: [],
      vitamins: [],
      minerals: [],
      greaseAcids: [
        {
          synthetic: true,
          id: 'omega3-ALA',
          label: 'Oméga 3 ALA',
          total: 0,
          contributors: [],
          unit: 'gram',
        },
        {
          synthetic: true,
          id: 'omega3-EPA-DHA',
          label: 'Oméga 3 EPA & DHA',
          total: 0,
          contributors: [],
          unit: 'gram',
          recommendation: {
            values: {
              min: 0.5,
              max: 3.0,
            },
          },
        },
        {
          synthetic: true,
          id: 'omega3-total',
          label: 'Total Oméga 3',
          total: 0,
          contributors: [],
          unit: 'gram',
        },
        {
          synthetic: true,
          id: 'omega6-total',
          label: 'Total Oméga 6',
          total: 0,
          contributors: [],
          unit: 'gram',
        },
      ],
      aminoAcids: [],
      micros: [],
      statusCount: {
        perfect: 0,
        deficiency: 0,
        insufficiency: 0,
        excess: 0,
      },
    },
  );

  // Calc Ratio
  //
  const omega6Total = result.greaseAcids.find(
    ({ id }) => id === 'omega6-total',
  );
  const omega3Total = result.greaseAcids.find(
    ({ id }) => id === 'omega3-total',
  );

  const fattyAcidsMono = result.greaseAcids.find(
    ({ label }) => label === 'Fatty acids, total monounsaturated',
  );
  const fattyAcidsPoly = result.greaseAcids.find(
    ({ label }) => label === 'Fatty acids, total polyunsaturated',
  );

  result.greaseAcids.push(
    {
      synthetic: true,
      id: 'ratio-mono-poly',
      label: 'Ratio Mono/Poly-insaturées',
      total: fattyAcidsMono.total / fattyAcidsPoly.total,
      contributors: [],
      unit: 'ratio',
      recommendation: {
        values: {
          min: 2.5,
        },
      },
    },
    {
      synthetic: true,
      id: 'ratio-omega3-omega6',
      label: 'Ratio Omega 6/Omega 3',
      total: omega6Total.total / omega3Total.total,
      contributors: [],
      unit: 'ratio',
      recommendation: {
        values: {
          min: 0,
          max: 5,
        },
      },
    },
  );
  return result;
}
